<template>
    <div id="play-movie-info-view">
        <a-row class="container-info">
            <a-col :span="4">
                <img :src="movieData.images.main" style="max-width:100%;max-height:100%;">
            </a-col>
            <a-col :span="18">
                <!-- 电影标签 -->
                <a-row class="info-genre" type="flex" justify="center" align="middle">
                    <a-col :span="8">
                        <span style="font-size: 22px; font-weight: 800">
                            {{ movieData.title }}
                        </span>
                    </a-col>
                    <a-col :span="8">
                        <span style="margin-right: 8px; font-size: 18px; font-weight: 600">发行国家:</span>
                        <template v-for="country in movieData.countries">
                            <a-tag
                                class="movie-country"
                                :key="country"
                            >
                                {{ country }}
                            </a-tag>
                        </template>
                    </a-col>
                    <a-col :span="8">
                        <span style="margin-right: 8px; font-size: 18px; font-weight: 600">影片类型:</span>
                        <template v-for="genre in movieData.genres">
                            <a-tag
                                class="movie-genre"
                                :key="genre"
                            >
                                {{ genre }}
                            </a-tag>
                        </template>
                    </a-col>
                </a-row>
                <!-- 电影评分 -->
                <a-row class="info-rating">
                    <a-col :span="8">
                    <a-statistic title="上映年份" :value="movieData.year" groupSeparator= "" style="margin-right: 50px">
                    </a-statistic>
                    </a-col>
                    <a-col :span="8">
                    <a-statistic title="Feedback" :value="movieData.votes" style="margin-right: 50px">
                        <template #suffix>
                            <a>
                                <a-icon type="like" @click.native="movieLikeClick()" />
                            </a>
                        </template>
                    </a-statistic>
                    </a-col>
                    <a-col :span="8">
                    <a-statistic title="评分" valueStyle="color:#ff6500;" :value="movieData.rating">
                        <template #suffix>
                        <span> / 10</span>
                        </template>
                    </a-statistic>
                    </a-col>
                </a-row>
                                            <!-- <a-descriptions-item label="导演" :span="3"> -->
                                <!-- <template v-for="director in movieData.directors"> -->
                                    <!-- {{ director }} -->
                                <!-- </template> -->
                            <!-- </a-descriptions-item> -->
                <!-- 电影详情 -->
                <a-row class="info-detail" type="flex" justify="center" align="top">
                    <a-col :span="20">
                        <a-descriptions title="剧情概要">
                            <a-descriptions-item label="" :span="3">
                                <span>{{ movieData.outline }}</span>
                            </a-descriptions-item>
                        </a-descriptions>
                    </a-col>
                </a-row>

            </a-col>
        </a-row>
        <!-- 相关影视 -->
        <a-row type="flex" justify="start" align="bottom" style="height: 32px">
            <a-col :span="2">
                <p class="row-title">
                    相关影视
                </p>
            </a-col>
            <a-col :span="20"></a-col>
        </a-row>
        <vue-horizontal-list class="thumb-list" :items="movieData.relation" :options="options">
            <template v-slot:default="{ item }">
                <div class="movie-cell">
                    <MovieThumbView
                            :movieData="item"
                            @clickMovie="clickMovie"
                        >
                    </MovieThumbView>
                    <div style="margin-top:10px">
                        <p class="title" align="left">
                            {{ item.title }}
                        </p>
                        <p class="sub-title" align="left">
                            {{ item.originaltitle }}
                        </p>
                    </div>
                </div>
            </template>
        </vue-horizontal-list>
        <!-- <a-tabs style="background:#161619;color: white;" default-active-key="1" tab-position="left" @change="infoTabClick()"> -->
            <!-- <a-tab-pane key="1" tab="内容简介"> -->


            <!-- </a-tab-pane> -->
            <!-- <a-tab-pane key="2" tab="演员表">
                <a-row type="flex" justify="center" align="top">
                    <a-col :span="24">
                        <a-card class="playinfo-actors" title="演员表" :bordered="false">
                            <a-card-grid style="width:25%;text-align:left" v-for="(actor, index) in movieData.actors" :key=index>
                                <a-avatar :src="actor.thumb" shape="square" :size="64" />
                                <span v-if="actor.name_cn" >
                                    <a> {{ actor.name_cn }} </a>
                                </span>
                                <span v-else>
                                    <a> {{ actor.name }} </a>
                                </span>
                            </a-card-grid>
                        </a-card>
                    </a-col>
                </a-row>
            </a-tab-pane> -->
            <!-- <a-tab-pane key="3" tab="相关视频"> -->

            <!-- </a-tab-pane> -->
        <!-- </a-tabs> -->


        <!-- <div class="play-movie-info-view-title" v-if="movieData" align="left">
            <div align="left">
                <h1>{{ movieData.title }}</h1>
            </div>
            <br>
            <div>
                <h1>🔥{{ movieData.votes }}</h1>
                <h1 style="color: red">{{ movieData.rating }}</h1>
            </div>
            <div>


                <div class="ant-col ant-col-16">
                    <h1 style="color: gray">导演：</h1>
                    <h1>{{ movieData.directors[0] }}</h1>
                    <h1 style="color: gray">主演：</h1>
                    <p v-for="(actor, index) in movieData.actors" :key="index" style="white-space:nowrap;">
                        {{ actor.name_cn }}
                    </p>
                    <h1 style="color: gray">剧情概要：</h1>
                    <h1>{{ movieData.outline }}</h1>
                </div>
            </div>
        </div>
        -->
    </div>
</template>

<script>
import MovieThumbView from '@/components/common/MovieThumbView'

export default {
    name: 'PlayMovieInfoView',
    data() {
        return {
            // vue-horizontal-list 设置
            options: {
                responsive: [
                    { end: 576, size: 1 },
                    { start: 576, end: 768, size: 3 },
                    { start: 768, end: 992, size: 4 },
                    { start: 992, end: 1200, size: 5 },
                    {start : 1200, size: 6}
                ],
                list: {
                },
                position: {
                    start: 1,
                },
                autoplay: { play: true, repeat: true, speed: 3000 },
            },
        }
    },
    components:{
        MovieThumbView,
    },
    props: {
        movieData: null
    },
    methods: {
        /**
         * @func 标签点击响应
         */
        infoTabClick() {
            console.log('click')
        },
        /**
         * @func like icon like
         */
        movieLikeClick() {
            console.log('like---')
            // TODO: 请求后端, like 电影

        },
        /**
        * @func 电影点击响应
        *
        * @param {object} selectMovie: 选中电影object
        */
        clickMovie(selectedMovie) {
            window.removeEventListener('scroll', this.scrollBottom)

            let routeData = this.$router.resolve({
                name: 'Play',
                params: {
                    uniqueId: selectedMovie.uniqueId
                }
            });
            window.open(routeData.href, '_blank');
            // this.$router.push({
            //     name: 'Play',
            //     params: {
            //         uniqueId: selectedMovie.uniqueId
            //     }
            // });
        },
    }
}
</script>


<style scoped>

.container-info {
    padding-top: 36px;
    padding-bottom: 36px;
}

.info-rating {
    margin-top: 36px;
    margin-bottom: 36px;
}

.info-genre {
    margin-top: 24px;
    margin-bottom: 24px;
}

.info-detail {
    margin-top: 36px;
    margin-bottom: 36px;
}

.info-rating-mark-title {
    Color: red !important;
    font-weight: 500px;
}

.movie-cell .title {
    font-size: 16px;
    font-weight: 700;
    font-family: PingFangdSC;
    margin-bottom: 1px;
}

.movie-cell .sub-title {
    font-size: 14px;
    font-weight: 500;
    font-family: PingFangdSC;
}

.movie-cell:hover p {
    color: #228efc;
}

.movie-country {
    color: #fa8c16;
    /* background: #fff7e6; */
    background: transparent;
    border-color: #ffd591;
    font-size: 18px;
    padding: 5px 8px 4px 8px;
}

.movie-genre {
    color: #13c2c2;
    /* background: #e6fffb; */
    background:transparent;
    border-color: #87e8de;
    font-size: 18px;
    padding: 5px 8px 4px 8px;
}

.row-title {
    color: white;
    font-size: 30px;
    font-weight: 1000;
    padding-bottom: 0px;
    font-family: "qiqi";
}

.thumb-list {
    margin-left: auto;
    margin-right: 36px;
    padding: 0px 12px 24px 12px;
    height: 100%;
}

</style>
