<template>

    <div class="movie-thumbnail">
        <div style="position:relative">
            <!-- 年份 -->
            <span class="movie-thumbnail-span-top-left">
                {{ movieData.year }}
            </span>
            <span class="movie-thumbnail-span-top-right">
                {{ movieData | getGenesToString }}
            </span>
            <!-- 播放量 -->
            <span class="movie-thumbnail-span-bottom-left">
                播放量: {{ movieData.votes }}
            </span>
            <!-- 评分 -->
            <span class="movie-thumbnail-span-bottom-right">
                {{ movieData.rating }}
            </span>
            <img class="movie-thumbnail" v-lazy="movieData.images.s_cover"
                style="max-width:100%;max-height:100%;vertical-align: middle; margin: 0 auto;"
                @click="clickMovie()">
            </div>
    </div>

</template>

<script>
export default {
    name: 'MovieThumbView',
    data() {
        return {
        };
    },
    props: ["movieData"],
        filters: {
        /**
         * @func 获取movie genes 并生成显示字符串 ( 取前3个 )
         * @param {Object} movie: 电影数据
         * @return {String}: genes 字符串
         */
        getGenesToString: function(movie) {
            var genesString = ""
            // 取 genre 前三个
            // console.log(movie)
            // console.log(movie.genres)
            for (var i=0; i< Math.min(movie.genres.length, 3); i++) {
                if (genesString.length > 0) {
                    genesString += ","
                }
                genesString += movie.genres[i]
            }
            return genesString
        }
    },
    methods: {
        /**
         * @func thumbnail点击响应
         * 调用父亲响应
         */
        clickMovie: function() {
            this.$emit('clickMovie', this.movieData)
        }
    }
};
</script>

<style>

.movie-thumbnail {
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.6s;
    padding-left: 2px;
    padding-right: 2px;
}

.movie-thumbnail:hover{
    transform: scale(1.03);
}

.movie-thumbnail-span-top-left {
    font-family: klavika;
    position: absolute;
    color: #4e2d03;
    font-size: 15px;
    font-weight: 800;
    z-index: 100;
    left: 0px;
    background-image: linear-gradient(90deg,#ffc19f,#fff7d9);
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    /* 圆角 */
    border-radius: 10px;
    margin-left: 8px;
    margin-top: 8px;
}

.movie-thumbnail-span-top-right {
    font-family: PingFangdSC;
    font-weight: 1000;
    position: absolute;
    color: #fff;
    z-index: 100;
    right: 0px;
    background-image: linear-gradient( -45deg,#ffab9c,#ff658d);
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    /* 圆角 */
    border-radius: 10px;
    margin-right: 8px;
    margin-top: 8px;
}

.movie-thumbnail-span-bottom-left {
    position: absolute;
    color:#fff;
    font-weight: 500;
    z-index:100;
    bottom: 0px;
    margin-left: 8px;
    margin-bottom: 8px;
}

.movie-thumbnail-span-bottom-right {
    font-family: klavika;
    position: absolute;
    color:#ff6500;
    font-size: 28px;
    font-weight: 1000;
    z-index: 100;
    bottom: 0px;
    right: 0px;
    margin-right: 8px;
    margin-bottom: 0px;
}

</style>
