<template>
    <div id="play-list-view" style="min-width: 400px">
        <div
            v-if="movieData"
            class="ant-col ant-col-4 play-list-view"
            :style="{
                textAlign: 'left',
                background: '#161619',
                color: 'white',
                overflow: 'auto',
                width: 100 + '%',
                height: playerHeight + 'px'
            }"
        >
            <!-- {{movieData.recommend}} -->
            <MovieItem v-for="movie in movieData.recommend" :key="movie.uniqueId" :item="movie"></MovieItem>
            <!-- <div  v-for="(movie, index) in movieData.recommend" :key=index style="padding-top:10px">
                <img
                    v-lazy="getLazyImage(movie.images.thumb)"
                    class="playlist-recommend-thumbnail"
                    style="max-width:30%;max-height:50%;"
                    @click="clickMovie(movie)"
                >

                <a class="play-list-movie-title" :href="'/play/movie/'+movie.uniqueId" target="_blank">{{ movie.title }}</a>
            </div> -->
        </div>
    </div>
</template>

<script>
import MovieItem from '@/components/play/MovieItem'

const loadingBannerImage = require('@/assets/images/banner-placeholder.png')
const errorBannerImage = require('@/assets/images/banner-placeholder.png')

export default {
    name: 'PlayListView',
    data() {
        return {
            loading: true,
            loadingMore: false,
            showLoadingMore: true,
            playerHeight: 0,            // 播放器高度
            data: [],
        }
    },
    components:{
        MovieItem,
    },
    props: {
        movieData: null,
    },
    methods: {
        /*
         * @func 监控窗口 resize
         */
        ListenPlayerHeight() {
            // 给窗口 初始化 高度
            this.playerHeight = document.getElementById('container-player').clientHeight
            const that = this
            window.onresize = () => {
                return (() => {
                    console.log(document.getElementById('container-player').clientHeight)
                    that.playerHeight = document.getElementById('container-player').clientHeight
                })()
            }
        },
        /**
         * @func thumbnail点击响应
         * 调用父亲响应
         */
        clickMovie: function(movie) {
            this.$emit('clickMovie', movie)
        },
        /**
         * @func 获得图片lazy加载对象
         */
        getLazyImage: function(imageURL) {
            return {
                src: imageURL, error: loadingBannerImage, loading: loadingBannerImage
            }
        }
    },
    mounted() {
        // 添加页面窗口Resize监控
        this.ListenPlayerHeight()
    }
}
</script>


<style scoped>

.playlist-recommend-thumbnail {
    /* ❗️❗️❗️ 固定视频比例 16:9 */
    aspect-ratio: 16/9;
}

</style>
