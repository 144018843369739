var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-width":"400px"},attrs:{"id":"play-list-view"}},[(_vm.movieData)?_c('div',{staticClass:"ant-col ant-col-4 play-list-view",style:({
            textAlign: 'left',
            background: '#161619',
            color: 'white',
            overflow: 'auto',
            width: 100 + '%',
            height: _vm.playerHeight + 'px'
        })},_vm._l((_vm.movieData.recommend),function(movie){return _c('MovieItem',{key:movie.uniqueId,attrs:{"item":movie}})}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }