<template>
    <div id="play-page">
        <section class="ant-layout">
            <header class="ant-layout-header">
                <Head></Head>
            </header>

            <main class="ant-layout-content main-section">
                <PlayView v-if="movieData" v-bind:movieData="movieData"></PlayView>
                <PlayMovieInfoView v-if="movieData" v-bind:movieData="movieData"></PlayMovieInfoView>
            </main>

            <footer class="ant-layout-footer">
                <Foot></Foot>
            </footer>
        </section>
    </div>
</template>

<script>

import Head from '@/components/common/Head'
import Foot from '@/components/common/Foot'
import PlayView from '@/components/play/PlayView'
import PlayMovieInfoView from '@/components/play/PlayMovieInfoView'
import { fetchPlayMovieService } from '@/services/playService.js'


export default {
    name: 'PlayPage',
    head: {
        title: "播放电影 - 熊猫侠",
        meta:[
            {
                name: 'description',
                content: '熊猫侠(www.pandaman.tv), 致力于打造海外华人最大的在线视频平台, 以高清的视频内容，流畅的视频体验.',
            },
            {
                name: 'keywords',
                content: '熊猫侠,视频,视频分享,视频搜索,视频播放,视频社区,海外视频,电影,电视剧,真人秀,美剧,动漫,综艺,华人,影院',
            }
        ]
    },
    data() {
        return {
            movieData: null,
        }
    },
    components:{
        Head,
        Foot,
        PlayView,
        PlayMovieInfoView,
    },
    mounted () {
        // 获取URL中电影uniqueId
        var movieUniqueId = this.$route.params.uniqueId

        // 异步请求电影数据
        fetchPlayMovieService(movieUniqueId, response => {
            this.movieData = response.data
            console.log(response.data)
        }, error => {
        })

    }
}
</script>
