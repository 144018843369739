<template>
    <div>
        <a-layout id="vue-core-video-player-box" class="player">
            <a-row id="video-play" type="flex" justify="center" align="middle">
                <a-col :span="18">
                    <div class="container-player" id="container-player" @click="singleClick()" @dblclick="doubleClick()">
                        <vue-core-video-player
                            v-if="movieData"
                            :src="movieData.signURL"
                            :cover="loadingImage"
                            :volue=0.3
                            :logo="loadingImage"
                            :loop=true
                            @timeupdate="playerTimeUpdate"
                            autoplay
                        >
                        </vue-core-video-player>
                    </div>
                </a-col>
                <a-col :span="6">
                    <PlayListView v-if="movieData" v-bind:movieData="movieData" @clickMovie="clickMovie"></PlayListView>
                </a-col>
            </a-row>
        </a-layout>
    </div>
</template>

<script>
import HLSCore from '@core-player/playcore-hls'

import PlayListView from '@/components/play/PlayListView'

const loadingImage = require('@/assets/images/placeholder.png')

export default {
    name: 'PlayView',
    data() {
        return {
            HLSCore,
            loadingImage: loadingImage,
            media: null,
            cookies: null,
            uniqueId: 0,
            playPosition: 0,
            clickTimer: null,
            pictureInPictureMode: false
        }
    },
    components: {
        PlayListView,
    },
    props: {
        movieData: null,
    },
    methods: {
        playerTimeUpdate() {
            /**
            * @func 播放器播放进度更新回调
            */
            // 当前播放进度每间隔 15秒 保存进度一次
            if (this.media.currentTime % 15 > 14) {
                //处理代码
                // alert(timeDisplay)
                this.playPosition = this.media.currentTime
                console.log('set position')
                console.log(this.media.currentTime)
                this.cookies.set(this.uniqueId, this.media.currentTime, "24h")
            }
        },
        /**
        * @func 电影点击响应
        *
        * @param {object} selectMovie: 选中电影object
        */
        clickMovie(selectedMovie) {
            window.removeEventListener('scroll', this.scrollBottom)

            let routeData = this.$router.resolve({
                name: 'Play',
                params: {
                    uniqueId: selectedMovie.uniqueId
                }
            });
            window.open(routeData.href, '_blank');
            // this.$router.push({
            //     name: 'Play',
            //     params: {
            //         uniqueId: selectedMovie.uniqueId
            //     }
            // });
        },
        /**
        * @func 电影单击 暂停播放响应
        */
        singleClick(){
            if (this.clickTimer){
                window.clearTimeout(this.clickTimer)
                this.clickTimer = null
            }
            this.clickTimer = window.setTimeout(function(){
                this.media = document.querySelector('video');
                if(this.media.paused) {
                    this.media.play();
                } else {
                    this.media.pause();
                }
            },300)
        },
        /**
        * @func 电影双击 全屏响应
        */
        doubleClick(){
            if (this.clickTimer){
                window.clearTimeout(this.clickTimer)
                this.clickTimer = null
            }
            this.media = document.querySelector('video');
            this.media.requestFullscreen();
        },
        /**
         * @func 事件监听: 页面滚动到一半( 响应画中画 )
         */
        scrollPicToPicListener() {
            // console.log('页面滚动ing...')
            // 滚动到超过一半 进入画中画状态
            var htmlHeight = document.body.scrollHeight || document.documentElement.scrollHeight;
            // clientHeight是网页在浏览器中的可视高度，
            var clientHeight = document.body.clientHeight || document.documentElement.clientHeight;
            //scrollTop是浏览器滚动条的top位置，
            var scrollTop = document.body.scrollTopi || document.documentElement.scrollTop;
            // console.log("scrolltop")
            // console.log(scrollTop)
            // console.log("clientheight")
            // console.log(clientHeight)
            //通过判断滚动条的top位置与可视网页之和与整个网页的高度是否相等来决定是否到达底部
            if(scrollTop > clientHeight/2 ) {
                // console.log("滚动过一半 的地方。。。。。。。。。。。。。")
                this.media = document.querySelector('video');

                // 事件锁
                if(!this.pictureInPictureMode) {
                    console.log("enterrrrrr  picture in picture")
                    this.media.requestPictureInPicture()
                    // 事件锁死
                    this.pictureInPictureMode = true
                }
            }
            else{

                if(this.pictureInPictureMode) {
                    console.log("exitttt  picture in picture")
                    document.exitPictureInPicture()
                    // 事件解锁
                    this.pictureInPictureMode = false
                }

            }
        },
    },
    mounted() {
        // 页面添加滚动监听
        window.addEventListener('scroll', this.scrollPicToPicListener)

        this.media = document.querySelector('video');
        this.cookies = this.$cookies
        this.uniqueId = this.movieData.uniqueId
        // 获取播放位置
        this.playPosition = this.cookies.get(this.uniqueId)
        console.log(this.media.currentTime)
        console.log(this.playPosition)
        // 设置当前播放位置
        this.media.currentTime = this.playPosition

        // 键盘监控
        document.onkeydown = event => {
            // 空格 32
            // 后退 37
            // 向上 38
            // 向上 38 (音量+)
            // 向下 40 (音量-)
            // esc 27 (退出全屏)
            let key = window.event.keyCode;
            console.log(key)
            // 空格 32
            if(key === 32){
                event.preventDefault()
                if(this.media.paused) {
                    this.media.play();
                } else {
                    this.media.pause();
                }
            }
            // 快进 39
            if(key === 39){
                event.preventDefault()
                this.media.currentTime += 15
            }
            // 后退 37
            if(key === 37){
                event.preventDefault()
                this.media.currentTime -= 15
            }
            // 向上 38 (音量+)
            if(key === 38){
                event.preventDefault()
                if (this.media.volume < 0.9) {
                    this.media.volume += 0.1
                }
            }
            // 向下 40 (音量-)
            if(key === 40){
                event.preventDefault()
                if (this.media.volume > 0.1) {
                    this.media.volume -= 0.1
                }
            }
           // esc 27 (退出全屏)
            if(key === 27){
                event.preventDefault()
                alert("exit")
                media.exitFullscreen()
            }
        }
    },
    destroyed () {
        // 页面销毁, 删除监听
        window.removeEventListener('scroll', this.scrollPicToPicListener)
    }
}

</script>

<style scoped>

.container-player {
    /* ❗️❗️❗️ 固定视频比例 16:9 */
    aspect-ratio: 16/9;
}

</style>
