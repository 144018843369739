<template>
    <div class="movie-item" @click="changeMovie(item)">
        <a-row>
            <a-col :span="10">
                <div class="cover">
                    <img
                        v-lazy="getLazyImage(item.images.fanart)"
                        @click="clickMovie(movie)"
                    >
                    <!-- <img :src="item.images.thumb" alt="cover" /> -->
                    <!-- <div class="duration">{{item.duration}}</div> -->
                    <div class="duration">{{item.runtime}} mins</div>
                </div>
            </a-col>
            <a-col :span="14">
                <div class="detail" >
                    <div class="title">{{item.title}}</div>
                    <!-- 电影标签 -->
                    <div>
                        <!-- 只展示前三个分类 -->
                        <template v-for="genre in item.genres.slice(0, 3)">
                            <a-tag
                                class="item-genre"
                                :key="genre"
                            >
                                {{ genre }}
                            </a-tag>
                        </template>
                    </div>
                    <!-- <div class="author " style="color:white">{{item.directors}}</div> -->
                    <div class="date">{{item.outline.substring(0,200)}}</div>
                </div>
            </a-col>
        </a-row>
    </div>
</template>

<script>

const loadingBannerImage = require('@/assets/images/banner-placeholder.png')
const errorBannerImage = require('@/assets/images/banner-placeholder.png')

export default {
    props: {
        item: Object,
    },
    name: 'MovieItem',
    methods: {
        // /**
        //   * @func 图片切换
        //   */
        // changeMovie () {
        //     if (location.search) {
        //         location.href = location.href.replace(/\?vid=([a-z]+)/, '?vid=' + this.item.id)
        //     } else {
        //         location.href = location.href + '?vid=' + this.item.id
        //     }
        // },
        /**
        * @func 电影点击响应
        *
        * @param {object} selectMovie: 选中电影object
        */
        changeMovie(selectedMovie) {
            window.removeEventListener('scroll', this.scrollBottom)

            let routeData = this.$router.resolve({
                name: 'Play',
                params: {
                    uniqueId: selectedMovie.uniqueId
                }
            });
            window.open(routeData.href, '_blank');
        },
        /**
         * @func 获得图片lazy加载对象
         */
        getLazyImage: function(imageURL) {
            return {
                src: imageURL, error: loadingBannerImage, loading: loadingBannerImage
            }
        }
    },
}
</script>

<style>
.movie-item {
    cursor: pointer;
    display: flex;
    margin-bottom: 10px;
}
.movie-item .cover {
    position: relative;
    width: 166px;
    overflow: hidden;
    background: #000;
}
.movie-item:hover .cover img {
    opacity: .7;
    transform: scale(1.1, 1.1);
}
.movie-item .cover img {
    width: 100%;
    transition: transform ease .25s;
}
.movie-item .cover .duration {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 3px 6px;
    line-height: 1.2em;
    background-color: rgba(0,0,0, .65);
    color: #fff;
    font-size: 12px;
    border-radius: 2px;
}
.movie-item .detail {
    padding-left: 10px;
    font-size: 14px;
    color: rgba(0,0,0, .45);
}
.movie-item .detail .title {
    color: white;
    font-size: 16px;
}
.movie-item:hover .detail .title {
    color: #228efc;
    font-weight: 800;
    font-size: 17px;
}
.movie-item .detail .author{
    font-size: 13px;
    line-height: 1em;
}

.movie-item .detail .item-genre {
    color: #13c2c2;
    /* background: #e6fffb; */
    background: transparent;
    border-color: #87e8de;
    /* border-color: transparent; */
}

.movie-item .detail .date {
    display: inline-block;
    padding: 3px 6px;
    max-height: 40px;
    line-height: 1.5em;
    /* background-color: #31C2F2; */
    color: #fff;
    font-size: 13px;
    border-radius: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.movie-item .detail .data.hot {
    background-color: #ff6060;
}

</style>
